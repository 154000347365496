/*==================================
  CSS DESIGN SYSTEM WITH THEMES
==================================*/

/* Base Colors - Used across all themes */
:root {
    /* Neutral Colors */
    --color-white: #ffffff;
    --color-light: #f8f9fa;
    --color-gray: #adb5bd;
    --color-dark: #343a40;

    /* Status Colors - Preserved across themes */
    --status-new: #adb5bd;
    --status-in-progress: #a8d8ea;
    --status-stuck: #ffd166;
    --status-complete: #70e000;
    --status-text-dark: #2c3e50;
    --status-text-light: #ffffff;
    --status-outline: #6c757d;

    /* Common Semantic Colors */
    --text-light: var(--color-white);
    --text-muted: var(--color-gray);
    --bg-body: var(--color-white);
    --bg-light: var(--color-light);

    /* Default Text Color - Can be overridden by themes */
    --text-color: #6c757d;
    --text-color-secondary: #6c757d;
    --text-color-disabled: #adb5bd;

    /* Status Colors */
    --color-danger: #dc3545;
    --color-danger-dark: #bd2130;
    --color-danger-light: rgba(220, 53, 69, 0.1);

    /* Default Card Background - Can be overridden by themes */
    --card-bg: var(--color-light);

    /* Spacing System */
    --space-xs: 0.25rem;
    /* 4px */
    --space-sm: 0.5rem;
    /* 8px */
    --space-md: 1rem;
    /* 16px */
    --space-lg: 1.5rem;
    /* 24px */
    --space-xl: 2rem;
    /* 32px */
    --space-xxl: 3rem;
    /* 48px */

    /* Typography */
    --font-size-xs: 0.75rem;
    /* 12px */
    --font-size-sm: 0.875rem;
    /* 14px */
    --font-size-md: 1rem;
    /* 16px */
    --font-size-lg: 1.25rem;
    /* 20px */
    --font-size-xl: 1.5rem;
    /* 24px */
    --font-size-xxl: 2rem;
    /* 32px */

    /* Border Radius */
    --radius-sm: 0.25rem;
    /* 4px */
    --radius-md: 0.5rem;
    /* 8px */
    --radius-lg: 1rem;
    /* 16px */
    --radius-pill: 50rem;
    /* Pill shape */

    /* Shadows */
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);

    /* Transitions */
    --transition-fast: 0.2s ease;
    --transition-normal: 0.3s ease;
    --transition-slow: 0.5s ease;
}

/*==================================
  THEME 1: NATURE GREEN (Default)
==================================*/
:root,
.theme-nature {
    /* Primary Colors */
    --color-primary: #D7E4B8;
    /* Main brand color (green) */
    --color-primary-light: #e8f0d4;
    /* Lighter variant */
    --color-primary-dark: #b8c78c;
    /* Darker variant */
    --color-secondary: #dee2e6;
    /* Secondary color */

    /* Accent Colors */
    --color-accent: #3a7d7e;
    /* Teal accent color */
    --color-accent-rgb: 58, 125, 126;
    /* For rgba() usage */
    --color-accent-dark: #2a5d5e;
    /* Darker accent */
}

/*==================================
  THEME 2: OCEAN BLUE
==================================*/
.theme-ocean {
    /* Primary Colors */
    --color-primary: #a8d8ea;
    /* Main brand color (light blue) */
    --color-primary-light: #d3eaf2;
    /* Lighter variant */
    --color-primary-dark: #7ab9d4;
    /* Darker variant */
    --color-secondary: #e6e6e6;
    /* Secondary color */

    /* Accent Colors */
    --color-accent: #aa4465;
    /* Raspberry accent color */
    --color-accent-rgb: 170, 68, 101;
    /* For rgba() usage */
    --color-accent-dark: #8a3651;
    /* Darker accent */
}

/*==================================
  THEME 3: SUNSET ORANGE
==================================*/
.theme-sunset {
    /* Primary Colors */
    --color-primary: #ffb347;
    /* Main brand color (orange) */
    --color-primary-light: #ffd28c;
    /* Lighter variant */
    --color-primary-dark: #ff9914;
    /* Darker variant */
    --color-secondary: #e6e6e6;
    /* Secondary color */

    /* Accent Colors */
    --color-accent: #5c6784;
    /* Slate blue accent color */
    --color-accent-rgb: 92, 103, 132;
    /* For rgba() usage */
    --color-accent-dark: #444e69;
    /* Darker accent */
}

/*==================================
  THEME 4: LAVENDER CALM
==================================*/
.theme-lavender {
    /* Primary Colors */
    --color-primary: #d4bfff;
    /* Main brand color (lavender) */
    --color-primary-light: #e8ddff;
    /* Lighter variant */
    --color-primary-dark: #b69aff;
    /* Darker variant */
    --color-secondary: #e6e6e6;
    /* Secondary color */

    /* Accent Colors */
    --color-accent: #5e8b7e;
    /* Sage green accent color */
    --color-accent-rgb: 94, 139, 126;
    /* For rgba() usage */
    --color-accent-dark: #4a7164;
    /* Darker accent */
}

/*==================================
  THEME SWITCHER COMPONENT
==================================*/
.theme-switcher {
    display: flex;
    gap: var(--space-sm);
    margin: var(--space-md) 0;
}

.theme-option {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all var(--transition-fast);
    position: relative;
    overflow: hidden;
}

.theme-option:hover {
    transform: scale(1.1);
}

.theme-option.active {
    border-color: var(--color-dark);
    transform: scale(1.1);
}

/* Theme preview colors */
.theme-option.theme-nature-preview {
    background-color: #D7E4B8;
}

.theme-option.theme-nature-preview::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #3a7d7e;
    border-top-left-radius: 100%;
}

.theme-option.theme-ocean-preview {
    background-color: #a8d8ea;
}

.theme-option.theme-ocean-preview::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #aa4465;
    border-top-left-radius: 100%;
}

.theme-option.theme-sunset-preview {
    background-color: #ffb347;
}

.theme-option.theme-sunset-preview::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #5c6784;
    border-top-left-radius: 100%;
}

.theme-option.theme-lavender-preview {
    background-color: #d4bfff;
}

.theme-option.theme-lavender-preview::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    background-color: #5e8b7e;
    border-top-left-radius: 100%;
}

/*==================================
  BUTTON COMPONENTS
==================================*/

/* Base Button */
.btn {
    border-radius: var(--radius-sm);
    transition: all var(--transition-normal);
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* Button Variants */
.btn-blue {
    background-color: var(--color-accent);
    color: var(--text-light);
    border-color: var(--color-accent);
}

.btn-blue:hover {
    background-color: var(--color-accent-dark);
    border-color: var(--color-accent-dark);
    color: var(--text-light);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

.btn-outline-blue {
    color: var(--color-accent);
    border-color: var(--color-accent);
    background-color: transparent;
}

.btn-outline-blue:hover {
    background-color: var(--color-accent);
    color: var(--text-light);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

/* Custom Button */
.btn-custom {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    height: 38px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 250px;
}

.btn-primary-custom {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    height: 38px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 250px;
    background-color: var(--color-primary);
    color: var(--text-color);
    border-color: var(--color-primary-dark);
}

.btn-primary-custom:hover {
    background-color: var(--color-primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

/* Button Sizes */
.btn-sm {
    padding: var(--space-xs) var(--space-sm);
    font-size: var(--font-size-sm);
}

.btn-md {
    padding: var(--space-sm) var(--space-md);
    font-size: var(--font-size-md);
}

.btn-lg {
    padding: var(--space-md) var(--space-lg);
    font-size: var(--font-size-lg);
}

/* Pill Button */
.btn-pill {
    border-radius: var(--radius-pill);
}

/*==================================
  FORM COMPONENTS
==================================*/

/* Input Groups */
.input-group {
    display: flex;
    width: 100%;
}

.col-12 form .input-group {
    max-width: 600px;
    margin: 0 auto;
}

.input-group .form-control {
    border-radius: var(--radius-sm);
    height: 38px;
    transition: border-color var(--transition-fast);
}

.input-group .form-control:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 0.25rem rgba(var(--color-accent-rgb), 0.25);
}

/* Input Group with Button */
.input-group .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group .btn {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-accent);
    color: var(--text-light);
    border-color: var(--color-accent);
}

/*==================================
  BLOCK-BASED DESIGN SYSTEM
==================================*/

/* Base Block - Foundation for all block elements */
.block {
    background-color: var(--card-bg);
    border-radius: var(--radius-md);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    transition: box-shadow var(--transition-normal), transform var(--transition-normal);
    overflow: hidden;
    /* Ensures content doesn't overflow rounded corners */
    border: none;
    margin-bottom: var(--space-lg);
}

.block:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

/* Block Header */
.block-header {
    padding: var(--space-md) var(--space-lg);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6c757d;
    /* Match sidebar text color */
}

/* Block Header Titles */
.block-header h1,
.block-header h2,
.block-header h3,
.block-header h4,
.block-header h5,
.block-header h6 {
    color: #6c757d;
    /* Match sidebar text color */
    margin-bottom: 0;
}

/* Block Body */
.block-body {
    padding: var(--space-lg);
}

/* Block Footer */
.block-footer {
    padding: var(--space-md) var(--space-lg);
    background-color: var(--bg-light);
    border-top: 1px solid var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Block Variants */
.block-primary {
    border-top: 4px solid var(--color-primary);
}

.block-accent {
    border-top: 4px solid var(--color-accent);
}

.block-success {
    border-top: 4px solid var(--status-complete);
}

.block-warning {
    border-top: 4px solid var(--status-off-track);
}

/* Block Sizes */
.block-sm {
    max-width: 400px;
}

.block-md {
    max-width: 600px;
}

.block-lg {
    max-width: 800px;
}

.block-full {
    width: 100%;
}

/* Block with accent color header */
.block-accent-header .block-header {
    background: linear-gradient(135deg, var(--color-accent) 0%, var(--color-accent-dark) 100%);
    color: var(--color-white);
}

/* Block with primary color header */
.block-primary-header .block-header {
    background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-dark) 100%);
    color: #6c757d;
    /* Match sidebar text color */
}

/* Square button with rounded corners */
.btn-square-rounded {
    width: 36px;
    height: 36px;
    border-radius: var(--radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

/* Date selector buttons */
.btn-date {
    background-color: var(--color-primary);
    border-color: var(--color-primary-dark);
}

.btn-date:hover {
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
}

.btn-date:disabled {
    background-color: var(--color-primary);
    border-color: var(--color-primary-dark);
    opacity: 0.6;
}

/*==================================
  CARD COMPONENTS (Legacy Support)
==================================*/

/* Base Card */
.card {
    background-color: var(--card-bg);
    box-shadow: var(--shadow-sm);
    transition: box-shadow var(--transition-normal), transform var(--transition-normal);
    overflow: hidden;
    /* Ensures content doesn't overflow rounded corners */
    border: none;
    margin-bottom: var(--space-lg);
}

.card:hover {
    box-shadow: var(--shadow-md);
}

/* Card Header */
.card-header {
    padding: var(--space-md) var(--space-lg);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-secondary);
}

/* Card Body */
.card-body {
    padding: var(--space-lg);
}

/* Card Footer */
.card-footer {
    padding: var(--space-md) var(--space-lg);
    background-color: var(--bg-light);
    border-top: 1px solid var(--color-secondary);
}

/* Rounded Card Corners */
.card.rounded-4 .card-header {
    border-top-left-radius: calc(var(--radius-lg) - 1px);
    border-top-right-radius: calc(var(--radius-lg) - 1px);
}

.card.rounded-4 .card-footer {
    border-bottom-left-radius: calc(var(--radius-lg) - 1px);
    border-bottom-right-radius: calc(var(--radius-lg) - 1px);
}

/* Section Card Variant */
.section-card {
    margin-bottom: var(--space-xl);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
}

.section-card:hover {
    box-shadow: var(--shadow-lg);
    transform: translateY(-2px);
}

.section-card .card-header {
    background: linear-gradient(to right, var(--color-white), var(--color-primary-light));
    border-bottom: 1px solid var(--color-primary-light);
    display: flex;
    align-items: center;
}

.section-card .card-header h4 {
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 0;
}

.section-card .card-body {
    background-color: var(--color-white);
}

/*==================================
  TASK & HABIT COMPONENTS
==================================*/

/* Base Item Styling */
.task-item,
.habit-item {
    padding: var(--space-sm) var(--space-md);
    border-radius: var(--radius-md);
    margin-bottom: var(--space-sm);
    background-color: var(--color-white);
    border-left: 3px solid var(--color-primary);
    transition: all var(--transition-fast);
    box-shadow: var(--shadow-sm);
    width: 100%;
    color: #6c757d;
    /* Match header text color */
}

.task-item:hover,
.habit-item:hover {
    background-color: #f8f9fa;
    /* Light grey hover color */
    box-shadow: var(--shadow-md);
    transform: none !important;
}

/* Status Variants */
.task-item.completed,
.habit-item.completed {
    border-left-color: var(--status-complete);
    background-color: rgba(144, 190, 109, 0.1);
}

.task-item.not-started,
.habit-item.not-started {
    border-left-color: var(--status-not-started);
}

.task-item.off-track,
.habit-item.off-track {
    border-left-color: var(--status-off-track);
    background-color: rgba(249, 199, 79, 0.1);
}

.task-item.on-track,
.habit-item.on-track {
    border-left-color: var(--status-on-track);
    background-color: rgba(39, 125, 161, 0.1);
}

/*==================================
  DRAG AND DROP COMPONENTS
==================================*/

/* Droppable Container */
.habit-droppable-container {
    min-height: 10px;
    padding-bottom: var(--space-md);
    /* Ensure there's space at the bottom for dragging */
}

/* Draggable Item */
.habit-draggable-item {
    display: flex;
    align-items: stretch;
    margin-bottom: var(--space-sm);
    border-radius: var(--radius-md);
    background-color: var(--bg-light);
    box-shadow: var(--shadow-sm);
    position: relative;
    transition: transform var(--transition-fast),
        box-shadow var(--transition-fast),
        background-color var(--transition-fast);
}

/* Dragging State */
.habit-draggable-item.is-dragging {
    background-color: var(--bg-white);
    box-shadow: var(--shadow-md);
    z-index: 10;
    transform: scale(1.02);
}

/* Drag Handle */
.habit-drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--space-sm);
    color: var(--text-muted);
    cursor: grab;
    border-top-left-radius: var(--radius-md);
    border-bottom-left-radius: var(--radius-md);
    background-color: rgba(0, 0, 0, 0.02);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    transition: color var(--transition-fast);
}

.habit-drag-handle:hover {
    color: var(--text-primary);
}

.habit-drag-handle:active {
    cursor: grabbing;
}

/* Habit Content Container */
.habit-content {
    flex: 1;
    width: 100%;
    /* Ensure it takes full width */
}

/* Habit Item within Draggable Container */
.habit-content .habit-item {
    margin-bottom: 0;
    border-left: none;
    /* Remove the left border since we're inside a container */
    background-color: transparent;
    box-shadow: none;
}

/* Prevent button clicks from starting drag operations */
.dragging-active button {
    pointer-events: none;
}

/* Smart Habit Streaks Component */
.streak-container {
    display: flex;
    align-items: center;
    margin-top: var(--space-xs);
}

.streak-indicator {
    display: flex;
    gap: var(--space-xs);
}

.streak-day {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--status-not-started);
}

.streak-day--complete {
    background-color: var(--status-complete);
}

.streak-day--missed {
    background-color: var(--status-off-track);
}

.streak-count {
    margin-left: var(--space-sm);
    font-size: var(--font-size-xs);
    color: var(--text-primary);
    font-weight: 500;
}

/*==================================
  LANDING PAGE COMPONENTS
==================================*/

/* Hover Effects */
.hover-lift {
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.hover-lift:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg) !important;
}

/* Feature Icons */
.feature-icon-container {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    transition: transform var(--transition-fast);
}

.feature-icon-container:hover {
    transform: scale(1.05);
}

.bg-primary-light {
    background-color: rgba(var(--color-accent-rgb), 0.1);
}

/* Reset styles for habit items in draggable containers */
.habit-content .habit-item,
.habit-content .habit-item:hover {
    transform: none !important;
    background-color: transparent;
    transition: none !important;
}

/*==================================
  TEXT COMPONENTS
==================================*/

/* Task and Habit Text */
.habit-text,
.task-text {
    font-size: var(--font-size-md);
    font-weight: 500;
    flex-grow: 1;
    padding-right: var(--space-md);
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6c757d !important;
    /* Force gray text color */
}

/* Completed Text */
.completed .habit-text,
.completed .task-text {
    color: #adb5bd !important;
    /* Force lighter gray for completed */
    text-decoration: line-through;
    opacity: 0.8;
}

/*==================================
  BLOCK SYSTEM
==================================*/

/* Block - Base */
.block {
    border-radius: var(--radius-md);
    box-shadow: var(--shadow-sm);
    overflow: hidden;
    margin-bottom: var(--space-md);
    width: 100%;
}

/* Block Elements */
.block__header {
    padding: var(--space-md);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--bg-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block__body {
    padding: var(--space-md);
    background-color: var(--bg-light);
}

/* Block Modifiers */
.block--primary {
    border-top: 3px solid var(--color-primary);
}

.block--sm {
    max-width: 500px;
}

/* Legacy support for existing blocks */
.block-header {
    padding: var(--space-md);
    background-color: var(--color-white);
    border-bottom: 1px solid var(--bg-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block-body {
    padding: var(--space-md);
    background-color: var(--bg-light);
}

.block-primary {
    border-top: 3px solid var(--color-primary);
}

.block-sm {
    max-width: 500px;
}

/*==================================
  INNER BLOCK SYSTEM
==================================*/

/* Inner Block - Base */
.inner-block {
    display: flex;
    align-items: center;
    padding: var(--space-md);
    border-radius: var(--radius-md);
    background-color: var(--color-white);
    margin-bottom: var(--space-sm);
    transition: background-color var(--transition-fast), transform var(--transition-fast);
    border: 1px solid transparent;
    width: 100%;
    /* Ensure full width */
}

.inner-block:hover {
    background-color: var(--color-white);
    border-color: var(--color-primary-light);
    transform: translateY(-2px);
    transition: transform var(--transition-fast), border-color var(--transition-fast);
}

/* Inner Block Modifiers */
.inner-block--habit,
.inner-block--task,
.inner-block--suggestion,
.inner-block--goal {
    box-shadow: var(--shadow-sm);
    cursor: pointer;
}

/* Inner Block - Content */
.inner-block__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

/* Inner Block - Text */
.inner-block__text {
    font-size: var(--font-size-md);
    font-weight: 500;
    color: var(--text-color-secondary) !important;
    /* Force gray text color */
    padding-right: var(--space-md);
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Inner Block - Image */
.inner-block__image {
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    border-bottom: 1px solid var(--bg-light);
}

.inner-block__image img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    display: block;
}

/* Inner Block - Actions */
.inner-block__actions {
    display: flex;
    gap: var(--space-sm);
    margin-left: auto;
}

/* Inner Block - States */
.inner-block--completed .inner-block__text {
    color: var(--text-color-disabled) !important;
    /* Force lighter gray for completed */
    text-decoration: line-through;
    opacity: 0.8;
}

/* Inner Block - Types */
.inner-block--habit,
.inner-block--task,
.inner-block--goal,
.inner-block--suggestion {
    border-left: 3px solid var(--color-primary-dark);
}

/* Make habits match tasks exactly */
.inner-block--habit {
    padding: var(--space-md);
    margin-bottom: var(--space-sm);
    min-height: auto;
}

.inner-block--habit .inner-block__content,
.inner-block--habit .inner-block__actions {
    padding: 0;
}

/* Inner Block - Card with image */
.inner-block--with-image {
    display: block;
    padding: 0;
    overflow: hidden;
}

/* Legacy support for existing classes */
.habit-content,
.task-content {
    flex-grow: 1;
}

.habit-text,
.task-text {
    font-size: var(--font-size-md);
    font-weight: 500;
    flex-grow: 1;
    padding-right: var(--space-md);
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6c757d !important;
    /* Force gray text color */
}

.completed .habit-text,
.completed .task-text {
    color: #adb5bd !important;
    /* Force lighter gray for completed */
    text-decoration: line-through;
    opacity: 0.8;
}

/*==================================
  BUTTON SYSTEM
==================================*/

/* Button Base */
.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--radius-sm);
    font-weight: 500;
    text-align: center;
    transition: all var(--transition-fast);
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    min-width: 100px;
    flex-shrink: 0;
    height: 36px;
    /* Fixed height for consistency */
}

/* Button Sizes */
.button--sm {
    font-size: var(--font-size-sm);
    padding: var(--space-xs) var(--space-sm);
    min-width: 80px;
}

.button--md {
    font-size: var(--font-size-md);
    padding: var(--space-xs) var(--space-md);
}

.button--lg {
    font-size: var(--font-size-lg);
    padding: var(--space-sm) var(--space-lg);
    min-width: 120px;
}

/* Button Types */
.button--primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary-dark);
    color: var(--text-color-secondary);
    /* Lighter text color */
}

.button--primary:hover {
    background-color: var(--color-primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

.button--success {
    background-color: var(--color-primary);
    border-color: var(--color-primary-dark);
    color: var(--text-color-secondary);
    /* Lighter text color */
}

.button--success:hover {
    background-color: var(--color-primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

.button--danger {
    background-color: var(--color-danger);
    border-color: var(--color-danger-dark);
    color: var(--text-light);
}

.button--danger:hover {
    background-color: var(--color-danger-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
}

/* Button Outline Variants */
.button--outline-primary {
    background-color: transparent;
    border-color: var(--color-primary-dark);
    color: var(--color-accent-dark);
    font-weight: 500;
}

.button--outline-primary:hover {
    background-color: var(--color-primary-light);
    color: var(--text-dark);
    transform: translateY(-1px);
}

.button--outline-success {
    background-color: transparent;
    border-color: var(--color-primary-dark);
    color: var(--status-complete);
    font-weight: 500;
}

.button--outline-success:hover {
    background-color: var(--color-primary-light);
    color: var(--text-dark);
    transform: translateY(-1px);
}

.button--outline-danger {
    background-color: transparent;
    border-color: var(--color-danger);
    color: var(--color-danger);
}

.button--outline-danger:hover {
    background-color: var(--color-danger-light);
    transform: translateY(-1px);
}

/* Button with Icon */
.button__icon {
    margin-right: var(--space-xs);
    display: inline-flex;
    align-items: center;
}

/* Button Icon Only */
.button--icon-only {
    min-width: 36px;
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Legacy support for existing buttons */
.habit-item .btn-success,
.task-item .btn-success {
    background-color: var(--color-primary);
    border-color: var(--color-primary-dark);
    color: var(--text-color-secondary);
    /* Lighter text color */
    font-weight: 500;
    padding: var(--space-xs) var(--space-sm);
    border-radius: var(--radius-sm);
    transition: all var(--transition-fast);
    white-space: nowrap;
    min-width: 100px;
    text-align: center;
    flex-shrink: 0;
}

.habit-item .btn-success:hover,
.task-item .btn-success:hover {
    transform: translateY(-1px);
    box-shadow: var(--shadow-sm);
    opacity: 0.9;
    background-color: var(--color-primary-dark);
    border-color: var(--color-primary-dark);
}

.habit-item .btn-outline-success,
.task-item .btn-outline-success {
    color: var(--color-primary);
    border-color: var(--color-primary);
    background-color: transparent;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    border-radius: 6px;
    transition: all 0.2s ease;
    white-space: nowrap;
    min-width: 100px;
    text-align: center;
    flex-shrink: 0;
}

.habit-item .btn-outline-success:hover,
.task-item .btn-outline-success:hover {
    background-color: rgba(var(--color-primary-rgb), 0.1);
    color: var(--color-primary-dark);
    transform: translateY(-1px);
}

/* Delete button styling */
.task-item .btn-outline-danger {
    color: #6c757d;
    border-color: #ced4da;
    background-color: transparent;
    font-weight: 500;
    padding: 0.375rem 0.75rem;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.task-item .btn-outline-danger:hover {
    background-color: rgba(108, 117, 125, 0.1);
    color: #5a6268;
    border-color: #5a6268;
    transform: translateY(-1px);
}

/* ======================================
   WELCOME SECTION STYLES
   ====================================== */
.welcome-gradient {
    background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
    color: var(--text-light);
    transition: all 0.3s ease;
    border-radius: var(--radius-md);
    overflow: hidden;
}

.welcome-gradient:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
}

/* Block-based welcome section */
.welcome-block {
    border-radius: var(--radius-md);
    overflow: hidden;
    margin-bottom: var(--space-lg);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow var(--transition-normal), transform var(--transition-normal);
}

.welcome-block:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.welcome-block .block-header {
    background: linear-gradient(135deg, var(--color-primary) 0%, var(--color-primary-light) 100%);
    color: var(--text-light);
    padding: var(--space-lg);
    border-bottom: none;
}

.welcome-block .block-body {
    background-color: var(--card-bg);
    padding: var(--space-lg);
}

/* ======================================
   GOAL STATUS BUTTON STYLES
   ====================================== */
.status-button {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.2s ease;
    flex-grow: 1;
}

/* Status button outline (non-selected state) */
.status-outline {
    background-color: transparent;
    border-color: #6c757d;
    color: #6c757d;
}

/* New status */
.status-new {
    background-color: #adb5bd;
    border-color: #adb5bd;
    color: #ffffff;
}

/* In Progress status */
.status-in-progress {
    background-color: #a8d8ea;
    border-color: #a8d8ea;
    color: #2c3e50;
}

/* Stuck status */
.status-stuck {
    background-color: #ffd166;
    border-color: #ffd166;
    color: #2c3e50;
}

/* Complete status */
.status-complete {
    background-color: #4caf50 !important;
    border-color: #4caf50 !important;
    color: #ffffff !important;
    font-weight: 600;
}

/* ======================================
   CATEGORY MODAL STYLES
   ====================================== */
.category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.category-card {
    background-color: var(--card-bg);
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1) !important;
    border-color: var(--primary-blue-light);
}

.category-icon {
    color: var(--primary-blue);
}

.category-selector {
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: #212529;
    /* Darker text color */
    font-weight: 600;
    /* Make text bolder */
}

.category-selector:hover {
    background-color: rgba(255, 255, 255, 0.9) !important;
    transform: translateY(-2px);
}

/* ======================================
   USER ITEMS STYLES (GOALS & HABITS)
   ====================================== */
.user-items-section .section-header {
    color: var(--text-primary);
}

.hover-elevate {
    transition: all 0.3s ease;
}

.hover-elevate:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
}

.goal-status-buttons {
    margin-top: 1rem;
}

.status-btn {
    background-color: var(--status-not-started);
    border: none;
    color: var(--text-primary);
    padding: 0.375rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.status-btn:hover {
    opacity: 0.9;
}

.status-btn.active {
    color: white;
    font-weight: 500;
}

.status-btn:nth-child(1).active {
    background-color: var(--primary-blue);
}

.status-btn:nth-child(2).active {
    background-color: var(--status-on-track);
}

.status-btn:nth-child(3).active {
    background-color: var(--status-off-track);
}

.status-btn:nth-child(4).active {
    background-color: var(--status-complete);
}

.empty-state {
    border: 1px dashed rgba(0, 0, 0, 0.1);
}

.goal-reference {
    background-color: rgba(13, 110, 253, 0.05);
}

/* ======================================
   AI SUGGESTION STYLES
   ====================================== */
.ai-suggestion-section .section-header {
    color: var(--text-primary);
}

.ai-suggestions-container {
    background-color: var(--card-bg);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.suggestion-item {
    background-color: rgba(13, 110, 253, 0.1);
    color: var(--primary-blue);
    border: 1px solid rgba(13, 110, 253, 0.2);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    display: inline-flex;
    align-items: center;
}

.suggestion-item:hover {
    background-color: var(--primary-blue);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 3px 10px rgba(13, 110, 253, 0.2);
}

.suggestion-item .fa-plus {
    font-size: 0.8rem;
    opacity: 0.7;
}

.suggestion-item:hover .fa-plus {
    opacity: 1;
}

/* ======================================
   HOME PAGE STYLES
   ====================================== */
.welcome-section {
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--primary-color-light);
    background: linear-gradient(to right, #f8f9fa, var(--primary-color-light));
    border-radius: 8px;
}

.welcome-title {
    font-size: 2.25rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
}

.welcome-title::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: var(--primary-blue);
    border-radius: 2px;
}

.home-alert {
    border-radius: 8px;
    border-left: 4px solid var(--primary-blue);
    background-color: rgba(13, 110, 253, 0.1);
    color: var(--text-primary);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
}

.home-alert::before {
    content: '⚠️';
    margin-right: 10px;
    font-size: 1.2rem;
}

.home-alert a {
    color: var(--primary-blue);
    text-decoration: none;
    font-weight: 600;
    transition: all 0.2s ease;
}

.home-alert a:hover {
    text-decoration: underline;
}

/* Daily Motivation styling */
.blockquote {
    border-left: 4px solid var(--primary-color);
    padding-left: 1rem;
    font-style: italic;
}

.blockquote p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text-primary);
}

/* Date navigation styling */
.date-navigation {
    background: white;
    padding: 1.25rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    margin-bottom: 1.5rem;
}

.date-navigation .btn-group {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
}

.date-navigation .btn-group .btn {
    border-radius: 0;
    border: 1px solid var(--primary-color-light);
    padding: 0.5rem 0.75rem;
}

.date-display {
    font-weight: 600;
    color: var(--text-primary);
    min-width: 140px;
    text-align: center;
    padding: 0.5rem 0.75rem;
    background-color: white;
    border: 1px solid var(--primary-color-light);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ======================================
   FEATURE SHOWCASE STYLES
   ====================================== */
.feature-container {
    margin-bottom: 2rem;
    padding: 0;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.feature-icon {
    background-color: var(--primary-color-light);
    color: var(--primary-blue);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    flex-shrink: 0;
}

/* For backward compatibility */
.logged-out-goals-container,
.logged-out-habits-container {
    margin-bottom: 2rem;
    padding: 0;
}

/* ======================================
   POPULAR ITEMS SECTION STYLES
   ====================================== */
.section h4 {
    margin-bottom: 1.25rem;
    color: var(--text-primary);
}

.section .row.g-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

.section .card {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    height: 100%;
    overflow: hidden;
}

.section .card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.card-img-top {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

/* SearchItem specific styling */
.card .card-title {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.3;
    margin-top: 0.5rem;
    text-align: left;
}

.card .badge {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: left;
}

.card .card-body {
    padding: 1rem;
    text-align: left;
}

/* ======================================
   UTILITY CLASSES
   ====================================== */
.date-navigation {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.date-navigation .btn-group {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.date-display {
    font-weight: 500;
    color: var(--text-primary);
    min-width: 140px;
    text-align: center;
    padding: 0.375rem 0.75rem;
    background-color: white;
    border: 1px solid var(--primary-color-light);
}

.collapsible-section {
    transition: none !important;
}

/* ======================================
   RESPONSIVE STYLES
   ====================================== */
@media (max-width: 767.98px) {

    /* Header and container adjustments */
    .page-header {
        padding: 0.75rem !important;
        margin-top: 0.5rem !important;
    }

    .col-md-8.col-sm-12 {
        padding-left: 0;
        padding-right: 0;
    }

    /* Feature containers */
    .feature-container {
        margin: 0;
    }

    .feature-container .card {
        border-radius: 0;
        margin-bottom: 0.5rem;
    }

    .feature-container .card-body {
        padding: 1rem !important;
    }

    .feature-container h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .feature-container .btn {
        width: 100%;
        margin-bottom: 0.5rem;
        padding: 0.5rem !important;
    }

    .feature-container .btn-sm {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem !important;
    }

    .feature-container .col-4 {
        padding: 0.25rem;
    }

    /* For backward compatibility */
    .logged-out-goals-container,
    .logged-out-habits-container {
        margin: 0;
    }

    /* Popular sections adjustments */
    .section .row {
        margin: 0 -8px;
    }

    .section .col-md-4,
    .section .col-sm-6,
    .section .col-12 {
        padding: 8px;
    }

    .section .card {
        margin: 0;
        height: 100%;
    }

    .section .card-body {
        padding: 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .section .card-title {
        font-size: 1rem;
        margin-bottom: 8px;
    }

    .section .card-text {
        font-size: 0.85rem;
        margin-bottom: 12px;
    }

    .section .badge {
        font-size: 0.75rem;
    }

    .section img {
        max-height: 120px;
        object-fit: cover;
        margin-bottom: 12px;
    }
}

/* Primary Blue Button Styles */
.btn-blue {
    background-color: var(--primary-blue);
    color: var(--text-light);
    border-color: var(--primary-blue);
}

.btn-blue:hover {
    background-color: var(--primary-blue-dark);
    border-color: var(--primary-blue-dark);
    color: var(--text-light);
}

.btn-blue:active {
    background-color: var(--primary-blue-dark) !important;
    border-color: var(--primary-blue-dark) !important;
}

.btn-blue:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-outline-blue {
    background-color: transparent;
    color: var(--primary-blue);
    border-color: var(--primary-blue);
}

.btn-outline-blue:hover {
    background-color: var(--primary-blue);
    color: var(--text-light);
    border-color: var(--primary-blue);
}

.btn-outline-blue:active {
    background-color: var(--primary-blue-dark) !important;
    border-color: var(--primary-blue-dark) !important;
    color: var(--text-light) !important;
}

.btn-outline-blue:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn-primary-custom {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    height: 38px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 250px;
    background-color: var(--primary-color);
    color: var(--text-primary);
    border-color: var(--primary-color-dark);
}

.btn-primary-custom:hover {
    background-color: var(--secondary-color);
    border-color: var(--primary-color-dark);
}

.btn-primary-custom:active {
    background-color: var(--primary-color);
}

.btn-primary-custom:disabled {
    background-color: var(--primary-color-light);
    opacity: 0.65;
}

.btn-primary-custom.selected {
    background-color: var(--primary-color);
}

.btn-primary-custom.selected:hover {
    background-color: var(--secondary-color);
}

.btn-primary-custom-outline {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    height: 38px;
    line-height: 24px;
    margin: 0 auto;
    max-width: 250px;
    background-color: transparent;
    color: var(--text-primary);
    border-color: var(--primary-color-dark);
}

.btn-primary-custom-outline:hover {
    background-color: var(--secondary-color);
}

.btn-primary-custom-outline:active {
    background-color: var(--primary-color);
}

.btn-primary-custom-outline:disabled {
    background-color: transparent;
    border-color: var(--primary-color-light);
    color: var(--text-primary);
    opacity: 0.65;
}

.btn-primary-custom-outline.selected {
    background-color: var(--primary-color);
}

.dashboard-btn {
    padding: 0.2rem 0.4rem;
    font-size: 0.825rem;
    line-height: 1.4;
    border: 1px solid var(--text-primary);
    margin-right: 0.2rem;
}

.rounded-btn {
    padding: 0.2rem 0.4rem;
    font-size: 0.825rem;
    line-height: 1.4;
    border: 1px solid var(--primary-color-dark);
    margin-right: 0.2rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sq-btn {
    height: 1.8rem;
    width: 1.8rem;
    text-align: center;
}

.rect-btn {
    width: 6rem;
}

/* Sidebar Styles */
/* Sidebar with original green color scheme */
.sidebar {
    border-right: 1px solid #e8f0d4;
    /* Original primary-color-light */
    min-height: 100vh;
    height: 100%;
    padding-top: 2rem;
    background-color: var(--card-bg);
}

#sidebar {
    background-color: var(--card-bg);
    border-right: 1px solid #e8f0d4;
    /* Original primary-color-light */
}

.sidebar .list-group {
    padding: 0 1rem;
}

.sidebar .list-group-item {
    padding: 1rem 1.5rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 8px !important;
    font-weight: 500;
    transition: all 0.2s ease;
    background-color: white;
}

.sidebar .list-group-item:hover {
    background-color: #e8f0d4;
    /* Original primary-color-light */
    color: #6c757d;
    /* Original text-primary */
    transform: translateX(5px);
}

.sidebar .list-group-item.active {
    background-color: #D7E4B8 !important;
    /* Original primary-color */
    color: #6c757d !important;
    /* Original text-primary */
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sidebar .list-group-item svg {
    width: 20px;
    margin-right: 12px;
    opacity: 0.8;
}

.sidebar .list-group-item.active svg {
    opacity: 1;
}

/* Page Layout Components */
.page-header {
    background: var(--bg-light);
    margin-bottom: 2rem;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: var(--shadow-sm);
}

.category-selector {
    cursor: pointer;
    border: 1px solid #6c757d;
    background-color: white;
    /* Changed to white background */
    transition: all 0.2s ease;
    font-weight: 600;
    /* Increased font weight */
    color: #212529;
    /* Darker text color */
}

.category-selector:hover {
    background-color: #6c757d;
    border-color: #adb5bd;
    color: white;
}

.category-selector svg {
    font-size: 1.1rem;
    opacity: 0.9;
}

.page-header h2 {
    color: var(--color-white);
    margin-bottom: 1rem;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.content-section {
    background: white;
    border-radius: 12px;
    padding: 1rem;
    margin-bottom: 2.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
}

.content-section:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-section h3 {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.goals-section h3 svg {
    color: var(--primary-color);
}

.goal-card {
    background: var(--card-bg);
    border-radius: 8px;
    padding: 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid var(--primary-color-light);
    transition: all 0.2s ease;
}

.goal-card:hover {
    border-color: var(--primary-color);
    background: white;
}

.goal-card.completed {
    background-color: var(--success-light);
    border-color: var(--success);
}

.goal-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.goal-actions button {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease;
}

/* Status Colors */
.status-not-started {
    background-color: var(--status-not-started);
    color: var(--text-primary);
}

.status-off-track {
    background-color: var(--status-off-track);
    color: var(--text-light);
}

.status-on-track {
    background-color: var(--status-complete);
    color: var(--text-light);
}

.status-complete {
    background-color: var(--status-on-track);
    color: var(--text-light);
}

/* Item Styles */
.not-started-item,
.off-track-item,
.on-track-item,
.complete-item {
    border-width: 1px;
    border-style: solid;
    border-left-width: 6px;
}

.not-started-item {
    border-color: var(--status-not-started);
}

.off-track-item {
    border-color: var(--status-off-track);
}

.on-track-item {
    border-color: var(--status-on-track);
}

.complete-item {
    border-color: var(--status-complete);
}

/* Icon Colors */
.icon-not-started {
    color: var(--status-not-started);
}

.icon-off-track {
    color: var(--status-off-track);
}

.icon-on-track {
    color: var(--status-on-track);
}

.icon-complete {
    color: var(--status-complete);
}

/* Layout Components */
.header-border {
    border-bottom: 2px solid var(--primary-color);
}

.footer-border {
    border-top: 2px solid var(--primary-color);
}

.bg-pr-clr {
    background-color: var(--primary-color);
}

.bg-sec-clr {
    background-color: var(--secondary-color);
}

/* Card Sizes */

.dash-card-xsm {
    min-height: 100px;
}

.dash-card-sm {
    min-height: 120px;
}

.dash-card-lg {
    min-height: 160px;
}

.drag-card-sm.dragging,
.drag-card-lg.dragging {
    min-height: auto;
}

/* Chart Styles */
.chart-container {
    max-width: 95%;
    margin: auto;
    padding: 10px;
}

.chart-button {
    color: var(--status-on-track);
}

.custom-toast {
    /* Adjust styles for mobile */
    max-width: 90%;
    /* Ensure it fits on smaller screens */
    margin: 0 auto;
    /* Center it */
}

@media (max-width: 768px) {
    .custom-toast {
        /* Additional styles for mobile */
        font-size: 14px;
        /* Smaller font size */
    }
}

/* Toast Styles */
.custom-toast-check {
    color: var(--status-on-track);
}

.custom-toast-progress {
    background: var(--status-on-track);
}

/* Utility Classes */
.bg-primary-color {
    background-color: var(--primary-color);
}

/* Comic Message */
.comic-message {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle at 50% 0%, #ffcc00, #ff6600);
    color: var(--text-light);
    padding: 20px 40px;
    border: 3px solid #ff6600;
    border-radius: 10px;
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation: popIn 0.5s ease-out, popOut 0.5s ease-in 2.5s;
}

.comic-message span {
    display: inline-block;
    animation: shake 0.5s ease-in-out infinite;
}

.comic-message .burst {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 60px;
    height: 60px;
    opacity: 0.8;
    animation: rotateBurst 2s linear infinite;
}

/* Animations */
@keyframes popIn {
    0% {
        transform: translate(-50%, -60%) scale(0.5);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
}

@keyframes popOut {
    0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -40%) scale(1.2);
        opacity: 0;
    }
}

@keyframes shake {

    0%,
    100% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    75% {
        transform: rotate(-5deg);
    }
}

@keyframes rotateBurst {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Suggested Goals Section */
.suggested-goals-section {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--primary-color-light);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.focus-area-goals {
    margin-bottom: 20px;
}

.focus-area-goals h4 {
    color: var(--primary-color-dark);
    font-weight: bold;
    margin-bottom: 10px;
}

.focus-area-goals ul {
    list-style-type: none;
    padding-left: 0;
}

.focus-area-goals li {
    background-color: var(--secondary-color);
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.focus-area-goals li:hover {
    background-color: var(--primary-color);
}

.focus-area-goals .card {
    border: none;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--primary-color-light);
}

.focus-area-goals .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.focus-area-goals .card-body {
    padding: 20px;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.focus-area-goals .card-title {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--primary-color-dark);
    margin-bottom: 10px;
}

.focus-area-goals .btn-primary-custom {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.focus-area-goals .btn-primary-custom:hover {
    background-color: var(--primary-color-dark);
    transform: scale(1.05);
}

/* Daily Motivation Section */
.daily-motivation {
    margin-bottom: 30px;
    padding: 20px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.daily-motivation .card-title {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--primary-color-dark);
}

.daily-motivation .card-text {
    font-size: 1.2em;
    color: var(--text-primary);
}

/* Relevant Goals Section */
.relevant-goals .card {
    border: none;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.relevant-goals .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.relevant-goals .card-body {
    background-color: var(--primary-color-light);
    padding: 20px;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.relevant-goals .card-title {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--primary-color-dark);
    margin-bottom: 10px;
}

.relevant-goals .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.relevant-goals .btn-primary:hover {
    background-color: var(--primary-color-dark);
    transform: scale(1.05);
}

/* Goal Context Card */
.goal-context-card {
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;
}

.goal-context-card .card-title {
    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 600;
}

.goal-context-card .text-muted {
    color: var(--text-primary) !important;
    font-size: 1.1rem;
    font-weight: 500;
}

/* Status Buttons Container */
.status-buttons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    width: 100%;
    margin-top: 1rem;
}

.status-btn {
    padding: 0.5rem 0.25rem;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    transition: all 0.2s ease;
}

.status-btn:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Collapse Button Styles */
.collapse-btn {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color-light);
    border: 1px solid var(--primary-color-dark);
    border-radius: 0.25rem;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease-in-out;
}

.collapse-btn:hover {
    background-color: var(--primary-color);
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.collapse-btn:active {
    transform: translateY(0);
    box-shadow: none;
}

/* Goal Action Buttons */
.goal-action-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.2rem 0.6rem;
    font-size: 0.825rem;
    line-height: 1.4;
    border: 1px solid var(--primary-color-dark);
    border-radius: 0.25rem;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

.goal-action-btn:hover {
    background-color: var(--primary-color);
    color: var(--text-primary);
    transform: translateX(2px);
}

.goal-action-btn svg {
    font-size: 0.75rem;
}

/* Sidebar Styles */
/* Sidebar styles will be defined as needed */

/* Footer */
.footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media Queries */
@media (max-width: 767px) {
    .header-content {
        padding: 15px;
    }

    .header-content h1 {
        font-size: 20px;
    }
}

.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* Aligns items to the bottom */
    height: 100%;
    /* Ensure the container has enough height */
    margin-bottom: 0;
    /* Remove any margin from the bottom */
}

/* Tutorial Styles */
.tutorial-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 1rem;
}

.tutorial-content {
    background: white;
    border-radius: 16px;
    width: 95%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10000;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.tutorial-content.explore-step {
    transform: scale(0.9);
    max-width: 350px;
}

.tutorial-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e9ecef;
}

.tutorial-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #343a40;
    margin: 0;
}

.tutorial-body {
    padding: 1.5rem;
    color: #495057;
    font-size: 1rem;
    line-height: 1.6;
}

.tutorial-explore-action {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.tutorial-items {
    margin-top: 1rem;
}

.tutorial-item {
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
}

.tutorial-item:last-child {
    border-bottom: none;
}

.tutorial-item h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
}

.tutorial-item p {
    margin: 0;
    color: #6c757d;
}

.tutorial-footer {
    padding: 1.5rem;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: center;
}

.tutorial-footer button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.explore-btn {
    background: #4CAF50;
    color: white;
}

.continue-btn {
    background: #6c757d;
    color: white;
}

.tutorial-footer button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
}

.tutorial-overlay::before {
    content: "Tutorial Mode";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #4CAF50;
    color: white;
    padding: 0.75rem;
    text-align: center;
    z-index: 10001;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .tutorial-overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    .tutorial-content {
        width: 500px;
    }

    .tutorial-header h3 {
        font-size: 1.75rem;
    }

    .tutorial-body {
        font-size: 1.1rem;
    }

    .tutorial-overlay::before {
        display: none;
    }
}